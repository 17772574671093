import { userRoles } from "../../contants";
import { encryptStorage } from "../../EncryptStorage";
import httpApi from "../http-api/http-api";

class AuthApi {
  authenticated = false;
  token = null;
  user = null;
  csUser = null;

  constructor() {
    this.token = localStorage.getItem("auth_token") || "";
    let user = JSON.parse(localStorage.getItem("user") || "{}");
    this.user = user || "";

    this.csToken = localStorage.getItem("cs_auth_token") || "";
    let csUser = JSON.parse(localStorage.getItem("cs_user") || "{}");
    this.csUser = csUser || "";
    
    const pathname = window.location.pathname;
    if (this.token && this.user && !pathname.includes('customer-support')) {
      this.authenticated = true;
      httpApi.setToken(this.token);
    }else if(this.csUser && this.csToken && pathname.includes('customer-support') ){
      this.authenticated = true;
      httpApi.setToken(this.csToken);
    }
  }

  getUser = () => {
    return this.user;
  };

  getCSUser = () => {
    return this.csUser;
  }

  getCSToken = () => {
    return this.csToken;
  }

  isCS_guest_user = () => {
    return encryptStorage.getItem('cs_guest_user')?true:false
  }

  isCS_impersonate_user = () => {
    return this.user.impersonation;
  }

  isPosUser = () =>{
    return this.user.role === userRoles.POS?true:false;
  }

  login = async (data) => {
    encryptStorage.removeItem("cs_guest_user");
    
    const user = await httpApi.sendPostRequest("/Account/login", data);
    const { errorMessage } = user;

    if (errorMessage) {
      throw new Error(errorMessage);
    }

    const token = user.token;

    this.authenticated = true;
    // console.log("login data",user)
    // if customer support user
    if (user.role === userRoles.CustomerService || user.role ===  userRoles.Delivery) {
      httpApi.setToken(token);
      localStorage.setItem('cs_auth_token', token)
      localStorage.setItem("cs_user", JSON.stringify(user));
      this.csUser = user;
      this.csToken = token;
    } else {
      httpApi.setToken(token);
      localStorage.setItem("auth_token", token);
      localStorage.setItem("user", JSON.stringify(user));
      this.token = token;
      this.user = user;
    }
  };
  
  forgotPassword = async (email) => {
    return await httpApi.sendGetRequest(
      `/Account/ForgotPassword/${email}`
    );
  }

  UpdatePassword = async (userId,data) => {
    return await httpApi.sendPostRequest(
      `/Account/${userId}/updatePassword`,data
    );
  }

  loginAsUser = async (userId) => {
    const user = await httpApi.sendPostRequest(
      `/Account/GetUserLogin/${userId}`
    );

    // console.log(user);

    const token = user.token;

    this.authenticated = true;
    this.token = token;
    this.user = user;

    httpApi.setToken(token);
    localStorage.setItem("auth_token", token);
    localStorage.setItem("user", JSON.stringify(user));
  };

  setUser(user) {
    this.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  }

  isAuthenticated = async () => {
    if (this.authenticated) {
      return true;
    } else {
      return false;
    }
  };

  logout = async (avoidRedirect) => {
    this.authenticated = false;
    this.token = null;
    this.user = null;

    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
    localStorage.removeItem("randomCartId");
    encryptStorage.removeItem("cs_guest_user");

    if (!avoidRedirect) {
      window.location.href = "/";
    }
  };

  csLogout = async (avoidRedirect) => {
    this.authenticated = false;
    this.token = null;
    this.user = null;

    localStorage.removeItem("cs_auth_token");
    localStorage.removeItem("cs_user");
    localStorage.removeItem("randomCartId");

    if (!avoidRedirect) {
      window.location.href = "/customer-support";
    }
  };

  clearStorage = async () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
    localStorage.removeItem("restaurant_token");
    localStorage.removeItem("restaurant");
    localStorage.removeItem("randomCartId");
    encryptStorage.removeItem("cs_guest_user");
  };
}

export default new AuthApi();
