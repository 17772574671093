import React, { Component } from "react";
import style from "./KitchenDashboard.module.scss";
import classnames from "classnames";
import time from "../../../core/lib/time";

import {LinearProgress, Snackbar } from "@material-ui/core";

import Chip from "@material-ui/core/Chip";
import shoppingCartIcon from "../../../assets/shopping-cart.png";

import { orderStatus, OrderTypes, OrderTypesByNumber } from "../../../core/contants";
import restaurantApi from "../../../core/api/restaurant-api/restaurant-api";

import Header from "./components/Header/Header";
import Page from "../shared/Page/Page";

import clockIcon from "./clock.svg";
import listIcon from "./list.svg";
import AddTime from "./components/AddTime/AddTime";
import moment from "moment";
import HtmlToPrint from "../../EPOS/HtmlToPrint";
import CustomAlert from "../../../shared/Alert/Alert";
import sound from "../../../assets/sound.mp3"
import orderApi from "../../../core/api/order-api/order-api";
import customerOrderApi from "../../../core/api/customer-order-api/customer-order.api";
import { convertToLast3digit } from "../../../utils/MainUtils";

export default class KitchenDashboard extends Component {
    kitchenPrintRef = React.createRef();

    state = {
        orderHistory: [],
        isLoading: true,
        restaurant: "",
        restaurantId: '',
        restaurantDelayTime: 0,
        soundAlert:false,
        newOrderAlert:false,
        newOrderAlertMsg:null,
        customAlertDesc:'',
        customAlert:false,
        selectedOrderForPrint:null,
        waitingOrderIds:[]
    };

    componentDidMount() {
        this.fetchOrderHistory(false);
        this.setTimers();

        if (!restaurantApi.isLoggedIn) {
            window.location.href = "/kitchen/";
        }

        var context = new AudioContext();
        if(context.state !== 'running'){
            this.setState({soundAlert:true})
        }
    }

    componentWillUnmount(){
        clearInterval(this.intervalRef);
    }

    enableSound(){
        var context = new AudioContext();
        context.resume().then(() => {
            console.log('Playback resumed successfully');
        });
        this.setState({soundAlert:false});
    }
    
    playSound(){
        let audio = new Audio(sound);
        audio.play();
    }

    setTimers() {
        const f = (n) => (n < 10 ? "0" + n : n);

       this.intervalRef =  setInterval(() => {
            this.fetchOrderHistory(true);
        }, 10000);
    }

    async fetchOrderHistory(isInterval) {
        this.setState({
            isLoading: true,
        });

        let orderHistory = await restaurantApi.getOrderHistory();
        if (orderHistory.length !== 0) {
            const kitchenOrderHistory = orderHistory.filter((i) => {
                return i.orderStatus <= 2;
            })
            this.setWaitingOrderList(kitchenOrderHistory);
            const isNewOrder = (isInterval &&  kitchenOrderHistory.length > 0 && kitchenOrderHistory.length > this.state.orderHistory.length);
            // console.log(this.state.orderHistory.length,kitchenOrderHistory.length,isNewOrder);
            if(isNewOrder)
                this.playSound()

            this.setState({
                orderHistory:kitchenOrderHistory ,
                restaurant: orderHistory[0].restaurant,
                restaurantId: orderHistory[0].restaurantId,
                restaurantDelayTime: orderHistory[0].restaurantDelayTime,
                isLoading: false,
                newOrderAlert:isNewOrder
            });
        } else {
            this.setState({
                isLoading: false,
                orderHistory:[]
            });
        }
    }

    AddTimeClick = async () => {
        await this.fetchOrderHistory()
    }

    getOrderSummary(orderSummary) {
        return orderSummary.map((i, j) => {
            return (
                <div key={j} className={style.orderItem}>
                    {i.quantity} x {i.productName}
                </div>
            );
        });
    }

    updateOrderState = async (order) => {
        this.setState({
            isLoading: true,
        });

        const nextState = order.orderStatus + 1;
        await restaurantApi.updateOrderState(order.restaurantOrderId, nextState);
        
        //ToDO : If order is walkin change status to collected once order Ready
        const orderDetails = await orderApi.getOrderDetails(order.orderId);
        if(nextState === orderStatus.ReadyOrder && orderDetails.orderType === OrderTypes.WalkIn){
            await customerOrderApi.markCollected(order.orderId);
        }

        this.fetchOrderHistory();
    };

    getStartCookingTime(orderHistoryItem) {
        // console.log(orderHistoryItem);
        if (orderHistoryItem.orderPrepTime === orderHistoryItem.restaurantPrepTime && orderHistoryItem.restaurantCount === 1) {
            return time.getStartCookingTime(orderHistoryItem.requestedDate, orderHistoryItem.restaurantPrepTime, 0, true)
        } else {
            if (!!orderHistoryItem.fristRestaurantCookingTime) {
                return time.getStartCookingTime(orderHistoryItem.fristRestaurantCookingTime, orderHistoryItem.restaurantPrepTime, orderHistoryItem.orderPrepTime, false);
            }
        }
        return 'Waiting';
    }

    setWaitingOrderList(kitchenOrderHistory){
        Array.prototype.diff = function(a) {
            return this.filter(function(i) {return a.indexOf(i) < 0;});
        };
        
        const waitingOrderList = kitchenOrderHistory.filter((res)=>{
            return this.getStartCookingTime(res) === "Waiting";
        }).map(res=>res.orderNumber);
        const {waitingOrderIds} = this.state;
        
        // check Is Waiting order Ready For cooking
        const readyOrderIds = waitingOrderIds.diff( waitingOrderList );
        // console.log("readyOrderIds",readyOrderIds) 
        if(readyOrderIds.length >0){
            setTimeout(()=>{
                this.playSound();
                this.setState({
                    newOrderAlert:true,
                    newOrderAlertMsg:`Order ${readyOrderIds.join()} now ready for cooking`
                })
            },1000)

        }

        const newWaitingOrderIds = new Set([...waitingOrderIds,...waitingOrderList]);
        //Removing ready orders from waiting orders
        readyOrderIds.forEach(id => {
            newWaitingOrderIds.delete(id)
        });
        
        // console.log("New Waiting Order list",[...newWaitingOrderIds])
        this.setState({
            waitingOrderIds:[...newWaitingOrderIds]
        })
    }

    isCookingTimePassed(orderHistoryItem) {
        const time = this.getStartCookingTime(orderHistoryItem);

        if (orderHistoryItem.orderStatusDescription !== 'Acknowledged') {
            return false;
        }

        if (time === 'Waiting') {
            return false;
        }

        const [md, t] = time.split(' ');
        const [date, month] = md.split('/');

        const year = new Date().getFullYear();

        const cookingTime = moment(`${year}-${month}-${date} ${t}`).toDate();

        if (Date.now() - cookingTime.getTime() > 10 * 60 * 1000) {
            return true;
        }

        return false;
    }

    renderPreferences(preferences) {
        return preferences.map((i, j) => {
            return (
                <div key={j}>
                    {i.productPreference} : {i.productPreferenceOption}
                </div>
            );
        });
    }

    renderIngredients(ingredients) {
        return ingredients.map((i, j) => {
            return <div className={style.item} key={j}>
                <Chip key={j} label={i.ingredient} size="small" />
            </div>;
        });
    }

    getOrderSummary(orderSummary) {
        return orderSummary.map((i, j) => {
            return (
                <div key={j} className={style.orderItem}>
                    <div className={style.title}>
                        {i.quantity} x {i.productName}
                    </div>
                    <div className={style.options}>
                        <div className={style.subtitle}>
                            Size : {i.productSizeVarientName}
                        </div>
                        {i.productPreferenceOptions && i.productPreferenceOptions.length > 0 ? (
                            <div className={style.subtitle}>
                                {this.renderPreferences(i.productPreferenceOptions)}
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {i.addedProductIngredients && i.addedProductIngredients.length > 0 ? (
                            <div className={classnames(style.subtitle, style.ingredients)}>
                                <div>Added Ingredients :</div>
                                <div className={style.items}>{this.renderIngredients(i.addedProductIngredients)}</div>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {i.deletedProductIngredients && i.deletedProductIngredients.length > 0 ? (
                            <div className={classnames(style.subtitle, style.ingredients)}>
                                <div>Removed Ingredients :</div>
                                <div className={style.items}>{this.renderIngredients(i.deletedProductIngredients)}</div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>

                </div>
            );
        });
    };

    async printTicket(index,orderHistoryItem){
        const time = this.getStartCookingTime(orderHistoryItem);
        if (time === 'Waiting') {
            this.setCustomAlert(true,'Cannot move to cooking as waiting')
            return false;
        }
        const FullOrderData = await restaurantApi.getFullKitchenOrderData(orderHistoryItem.orderId);

        this.setState({
            selectedOrderForPrint:FullOrderData
        },()=>{
            setTimeout(()=>{
                this.kitchenPrintRef.current.Print();
                setTimeout(()=>this.kitchenPrintRef.current.Print(),1000)
            },500)
        })
  
    }

    setCustomAlert(open,description){
        this.setState({
            customAlert:open,
            customAlertDesc:description
        })
    }
    listOfOrderRestaurants(order){
        let contentString = "";
        order.restaurants.map((r) => {
            contentString += `${r.restaurant} (${r.restaurantCookingTime}mins)\n`
            return r;
        });
        return contentString;
    }

    renderOrderItems() {
        const { orderHistory } = this.state;
        return orderHistory.map((i, j) => {
            return (
                <div
                    className={style.orderCard}
                    key={j}
                    onClick={() => {
                        if(i.orderStatus === 1){
                            this.printTicket(j,i)
                        }
                        if (i.orderPrepTime === i.restaurantPrepTime) {
                            this.updateOrderState(i);
                        }
                        else {
                            if (!!i.fristRestaurantCookingTime) {
                                this.updateOrderState(i);
                            }
                        }

                    }
                    }
                >
                    <div
                        className={classnames(style.header, {
                            [style.newOrder]: i.orderStatus === orderStatus.NewOrder,
                            [style.ackOrder]: i.orderStatus === orderStatus.AcknowledgedOrder,
                            [style.cooking]: i.orderStatus === orderStatus.CookingOrder,
                        })}
                    >
                        <div className={style.orderId}>#{convertToLast3digit(i.orderNumber)}</div>
                        <div className={style.orderState}>
                            <div className={style.badge}>{i.orderStatusDescription}</div>
                        </div>
                        <div className={style.orderState}>
                            <div className={style.badge}>{OrderTypesByNumber[i.orderType]}</div>
                        </div>
                        <div className={style.timer}>
                            {/* {countdown[i.orderId]} */}
                        </div>
                    </div>
                    {/* <HtmlToPrint ref={this.getOrCreateRef(j)} data={i} /> */}

                    <div className={style.orderSummary}>
                        <div className={style.ordersList}>
                            {this.getOrderSummary(i.orderSummary)}
                        </div>
                        {
                            !!i.specialNote && i.orderStatus === orderStatus.CookingOrder &&
                            <div>
                                <div className={style.deliveryZone}>
                                    <div className={style.title}>
                                        Special Note :
                                    </div>
                                    <div className={style.subtitle}>
                                        {i.specialNote}
                                    </div>
                                </div>
                            </div>

                        }
                        <div>
                            <div className={style.deliveryZone}>
                                <div className={style.title}>
                                    Delivery Zone :
                                </div>
                                <div className={style.subtitle}>
                                    {i.deliveryZoneName}
                                </div>
                            </div>
                        {
                            !!i.deliveryAddress && i.orderStatus === orderStatus.CookingOrder ?
                                
                                <div className={style.deliveryZone}>
                                    <div className={style.title}>
                                        Delivery Address :
                                    </div>
                                    <div className={style.subtitle}>
                                        {i.deliveryAddress}
                                    </div>
                                </div>
                            : <div></div>

                        }
                        
                        </div>
                    </div>
                    
                    <div className={style.footer}>
                        <div className={style.countdown}>
                            No Restaurant : {i.restaurantCount}
                            <img className={style.listIcon} src={listIcon} alt="restaurants" title={this.listOfOrderRestaurants(i)}></img>
                        </div>
                    </div>
                    <div className={classnames(style.footer, {
                        [style.warn]: this.isCookingTimePassed(i)
                    })}>
                        <div className={style.startTime}>
                            Start Cooking : {this.getStartCookingTime(i)}
                        </div>

                        <div className={style.countdowns}>
                            <div className={style.countdown}>
                                <img src={clockIcon} alt="" /> Target Time : {time.getTargetTime(i.requestedDate)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    handleCloseNewOrderAlert(){
        this.setState({newOrderAlert:false,newOrderAlertMsg:null})
    }

    render() {
        const { 
            isLoading, 
            restaurant, 
            orderHistory, 
            restaurantId, 
            restaurantDelayTime ,
            soundAlert,
            newOrderAlert,
            newOrderAlertMsg,
            customAlert,
            customAlertDesc,
            selectedOrderForPrint
        } = this.state;
        return (
            <Page>
                <CustomAlert
                    title="Alert!"
                    description="Please click enable to start alert sound."
                    open={soundAlert}
                    okButtonText="Enable"
                    onOkClick={()=>this.enableSound()}
                />
                <CustomAlert
                    title="Alert!"
                    description={customAlertDesc}
                    open={customAlert}
                    okButtonText="ok"
                    onOkClick={()=>this.setCustomAlert(false,'')}
                />
                <Snackbar
                    open={newOrderAlert}
                    onClose={()=>this.handleCloseNewOrderAlert()}
                    message={newOrderAlertMsg?newOrderAlertMsg:"New Order Available"}
                    key="GrowTransition"
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                <HtmlToPrint ref={this.kitchenPrintRef} data={selectedOrderForPrint} />

                <div className={style.container}>
                    <Header restaurant={restaurant} orderHistory={orderHistory}/>
                    <div className={style.progress}>
                        {isLoading && <LinearProgress />}
                    </div>
                    {/* <ThermalPrinter /> */}
                    
                    <AddTime restaurantId={restaurantId} updateOrders={this.AddTimeClick} restaurantDelayTime={restaurantDelayTime} />

                    <div className={style.content}>
                        {orderHistory.length !== 0 ? (
                            <div className={style.orders}>{this.renderOrderItems()}</div>

                        ) : (
                            <div className={style.emptyBasket}>
                                <div className={style.img}>
                                    <img src={shoppingCartIcon} />
                                </div>
                                Your Order History is Empty
                            </div>
                        )}
                    </div>
                </div>
            </Page>
        );
    }
}



