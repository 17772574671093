import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./SignUp.scss";

import logo from "../../assets/logo.png";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhoneIcon from "@material-ui/icons/Phone";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import TermsBar from "../../shared/TermsBar/TermsBar";

import accountApi from "../../core/api/account-api/account-api";
import authApi from "../../core/api/auth-api/auth-api";

export default class SignUp extends Component {
  state = {
    showPassword: false,
    email: '',
    password: '',
    cPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',

    emailError: false,
    passwordError: false,
    firstNameError: false,
    lastNameError: false,
    phoneNumberError: false,
    emailErrorText: '',
    passwordErrorText: '',
    cPasswordErrorText: '',
    firstNameErrorText: '',
    lastNameErrorText: '',
    phoneNumberErrorText: '',
    validateError: '',
  };

  handleRegister = async () => {
    const { email, password, cPassword, firstName, lastName, phoneNumber } = this.state

    this.setState({
      validateError: ""
    })

    let emailErrorText = ''
    let passwordErrorText = ''
    let cPasswordErrorText = ''
    let firstNameErrorText = ''
    let lastNameErrorText = ''
    let phoneNumberErrorText = ''

    let passwordError = false
    let emailError = false
    let firstNameError = false
    let lastNameError = false
    let phoneNumberError = false

    if (!email) {
      emailError = true
      emailErrorText = 'This field cannot be empty'
    }

    if (!password && !cPassword) {
      passwordError = true
      passwordErrorText = 'This field cannot be empty'
      cPasswordErrorText = 'This field cannot be empty'
    }
    else {
      if (cPassword !== password) {
        passwordError = true
        cPasswordErrorText = 'Password did not match'
        passwordErrorText = 'Password did not match'
      }
    }



    if (!firstName) {
      firstNameError = true
      firstNameErrorText = 'This field cannot be empty'
    }
    if (!lastName) {
      lastNameError = true
      lastNameErrorText = 'This field cannot be empty'
    }
    if (!phoneNumber) {
      phoneNumberError = true
      phoneNumberErrorText = 'This field cannot be empty'
    }

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(email)) {
      emailError = true
      emailErrorText = "Please enter valid email address.";
    }

    let data = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      password: password
    }

    let loginData = {
      email: email,
      password: password
    }

    if (!emailError && !passwordError && !firstNameError && !lastNameError && !phoneNumberError && !cPasswordErrorText) {

      try {
        await accountApi.create(data);
        console.log("success")
        await authApi.login(loginData);
        const user = await authApi.getUser();

        if (user.roleDescription === "POS") {
          this.props.history.push(`/restaurant/${user.restaurantId}`);
        } else {
          this.props.history.push("/order-page-one");
        }
      } catch (err) {
        console.log(err)
        this.setState({
          validateError: err.message
        })
      }
    } else {
      this.setState({
        emailError: emailError,
        emailErrorText: emailErrorText,
        passwordError: passwordError,
        passwordErrorText: passwordErrorText,
        cPasswordErrorText: cPasswordErrorText,
        firstNameError: firstNameError,
        firstNameErrorText: firstNameErrorText,
        lastNameError: lastNameError,
        lastNameErrorText: lastNameErrorText,
        phoneNumberError: phoneNumberError,
        phoneNumberErrorText: phoneNumberErrorText
      })
    }

  }

  handleChange = (event) => {
    event.persist();

    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handlePhoneChange = (event) => {
    event.persist();
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    // if (onlyNums.length < 12) {
    this.setState({ [event.target.name]: onlyNums });
    // }
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { validateError } = this.state
    return (
      <div className="signup-container">
        <Paper className="paper" elevation={5}>
          <img src={logo} />

          <Divider variant="middle" />

          <div className="title">Sign Up</div>
          <form onSubmit={this.handleRegister}>
            <div className="split-form">
              <Grid container justify="center">
                <div className="login-form">
                  <div className="form-item">
                    <div className="name">FIRST NAME</div>
                    <TextField
                      onChange={this.handleChange}
                      error={this.state.firstNameError}
                      helperText={this.state.firstNameErrorText}
                      name="firstName"
                      variant="outlined"
                      fullWidth />
                  </div>
                  <div className="form-item">
                    <div className="name">PHONE NUMBER</div>
                    <TextField
                      onChange={this.handlePhoneChange}
                      error={this.state.phoneNumberError}
                      helperText={this.state.phoneNumberErrorText}
                      name="phoneNumber"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled>
                              <PhoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-item">
                    <div className="name">PASSWORD</div>
                    <TextField
                      onChange={this.handleChange}
                      error={this.state.passwordError}
                      helperText={this.state.passwordErrorText}
                      name="password"
                      variant="outlined"
                      fullWidth
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled>
                              <LockIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="login-form">
                  <div className="form-item">
                    <div className="name">LAST NAME</div>
                    <TextField
                      onChange={this.handleChange}
                      error={this.state.lastNameError}
                      helperText={this.state.lastNameErrorText}
                      name="lastName"
                      variant="outlined"
                      fullWidth />
                  </div>

                  <div className="form-item">
                    <div className="name">EMAIL</div>
                    <TextField
                      onChange={this.handleChange}
                      error={this.state.emailError}
                      helperText={this.state.emailErrorText}
                      name="email"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled>
                              <PersonIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-item">
                    <div className="name"> CONFIRM PASSWORD</div>
                    <TextField
                      onChange={this.handleChange}
                      error={!!this.state.cPasswordErrorText}
                      helperText={this.state.cPasswordErrorText}
                      name="cPassword"
                      variant="outlined"
                      fullWidth
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled>
                              <LockIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </div>
            <div className="alert">
              <Collapse in={!!validateError}>
                <Alert severity="error">
                  {/* This user has already been registered please enter new details! */}
                  {validateError}
                </Alert>
              </Collapse>
            </div>

            <div className="login">
              <Button
                onClick={this.handleRegister}
                variant="contained"
                color="primary"
                endIcon={<NavigateNextIcon />}
              >
                SIGN UP
              </Button>
            </div>
          </form>

          <div className="options">
            <div className="signup">
              <Grid container alignItems="center" justify="center">
                <p>ALREADY HAVE AN ACCOUNT?</p>
                <Link to="/login">
                  <p id="red-button">
                    <u>LOGIN</u>
                  </p>
                </Link>
              </Grid>
            </div>
          </div>
        </Paper>
        <TermsBar />
      </div>
    );
  }
}
